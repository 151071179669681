import { NotistackSnackbar } from 'components/snackbar/NotistackSnackbar';
import { SnackbarProvider } from 'notistack';

// import XMSNotification from './XMSNotification';

type Props = {
  children: React.ReactNode;
};

const NotistackSnackbarProviderWrapper = (props: Props) => (
  <SnackbarProvider
    Components={{
      // XMSNotification: XMSNotification,
      error: NotistackSnackbar,
      info: NotistackSnackbar,
      success: NotistackSnackbar,
      warning: NotistackSnackbar,
    }}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    autoHideDuration={5000}
    maxSnack={1}
  >
    {props.children}
  </SnackbarProvider>
);

export default NotistackSnackbarProviderWrapper;
