import { NotifiFrontendClient } from '@notifi-network/notifi-frontend-client';

import { ClientActions, ClientActionTypes } from '../actions/clientActions';

export interface ClientState {
  client: NotifiFrontendClient | null;
}

const initialState: ClientState = {
  client: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clientReducer = (state = initialState, action: ClientActions) => {
  switch (action.type) {
    case ClientActionTypes.SET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };

    case ClientActionTypes.RESET_CLIENT:
      return {
        ...state,
        client: null,
      };

    default:
      return state;
  }
};
