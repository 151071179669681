import { User as ConfigCatUser } from 'configcat-common';
import { createClient } from 'configcat-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getEnv } from 'utils/envUtils';
import { notNullOrUndefined } from 'utils/notNullOrUndefined';
import getStorage from 'utils/storageUtils';
import { isEmpty } from 'utils/stringUtils';
import { v4 as uuid } from 'uuid';

const API_KEY = process.env.REACT_APP_CONFIG_CAT_SDK_KEY;
const SESSION_ID = `SESSION-${uuid()}`;

const SUPPORTED_FLAGS = [
  'isNotificationsReadStatusEnabled',
  'isCommunityManagerEnabled',
  'isMultiSigSafeAlertEnabled',
  'isJabberChatEnabled',
  'isXMSReadUnreadEnabled',
  'isHawksightEnabled',
  'isTalismanWalletEnabled',
  'isPolkadotJsWalletEnabled',
  'isSubWalletWalletEnabled',
  'isPolygonWalletsEnabled',
  'isMobileEnabled',
  'isXmsMuteEnabled',
  'isArbitrumEnabled',
  'isBinanceEnabled',
  'isMobileStorePageEnabled',
  'isIntercomChatEnabledInHub',
  'isRichTextEditorEnabled',
  'isDiscordEnabled',
  'isSuiEnabled',
  'isSmsEnabled',
  'isNewsletterEnabled',
  'isSnapshotEnabled',
  'isAptosEnabled',
  'isAcalaEnabled',
  'isFusionBalanceChangeEnabled',
  'isHyperspaceFusionEnabled',
  'isSdk3Enabled',
] as const;

// Get the defaults from environment because we don't use ConfigCat on local
const DEFAULTS: Record<FeatureFlagKey, boolean> = {
  isAcalaEnabled: process.env.REACT_APP_IS_ACALA_ENABLED === 'true',
  isAptosEnabled: process.env.REACT_APP_IS_APTOS_ENABLED === 'true',
  isArbitrumEnabled: process.env.REACT_APP_ARBITRUM_ENABLED === 'true',
  isBinanceEnabled: process.env.REACT_APP_BINANCE_ENABLED === 'true',
  isCommunityManagerEnabled: process.env.REACT_APP_COMMUNITY_MANAGER_ENABLED === 'true',
  isDiscordEnabled: process.env.REACT_APP_IS_DISCORD_ENABLED === 'true',
  isFusionBalanceChangeEnabled: process.env.REACT_APP_IS_FUSION_BALANCE_CHANGE_ENABLED === 'true',
  isHawksightEnabled: process.env.REACT_APP_IS_HAWKSIGHT_ENABLED === 'true',
  isHyperspaceFusionEnabled: process.env.REACIT_APP_IS_HYPERSPACE_ENABLED === 'true',
  isIntercomChatEnabledInHub: process.env.REACT_APP_IS_INTERCOM_CHAT_ENABLED_IN_HUB === 'true',
  isJabberChatEnabled: process.env.REACT_APP_JABBER_CHAT_ENABLED === 'true',
  isMobileEnabled: process.env.REACT_APP_IS_MOBILE_ENABLED === 'true',
  isMobileStorePageEnabled: process.env.REACT_APP_MOBILE_STORE_PAGE_ENABLED === 'true',
  isMultiSigSafeAlertEnabled: process.env.REACT_APP_IS_MULTI_SIG_SAFE_ALERT_ENABLED === 'true',
  isNewsletterEnabled: process.env.REACT_APP_NEWSLETTER_ENABLED === 'true',
  isNotificationsReadStatusEnabled: process.env.REACT_APP_INBOX_READ_STATE_ENABLED === 'true',
  isPolkadotJsWalletEnabled: process.env.REACT_APP_POLKADOTJS_WALLET_ENABLED === 'true',
  isPolygonWalletsEnabled: process.env.REACT_APP_POLYGON_WALLETS_ENABLED === 'true',
  isRichTextEditorEnabled: process.env.REACT_APP_IS_RICH_TEXT_EDITOR_ENABLED === 'true',
  isSdk3Enabled: process.env.REACT_APP_IS_SDK3_ENABLED === 'true',
  isSmsEnabled: process.env.REACT_APP_IS_SMS_ENABLED === 'true',
  isSnapshotEnabled: process.env.REACT_APP_IS_SNAP_SHOT_ENABLED === 'true',
  isSubWalletWalletEnabled: process.env.REACT_APP_SUBWALLET_WALLET_ENABLED === 'true',
  isSuiEnabled: process.env.REACT_APP_IS_SUI_ENABLED === 'true',
  isTalismanWalletEnabled: process.env.REACT_APP_TALISMAN_WALLET_ENABLED === 'true',
  isXMSReadUnreadEnabled: process.env.REACT_APP_XMS_READ_UNREAD_ENABLED === 'true',
  isXmsMuteEnabled: process.env.REACT_APP_IS_XMS_MUTE_ENABLED === 'true',
};

type GetConfigValueFn = (
  key: FeatureFlagKey,
  callback: (value: boolean) => void,
  userAttributes: UserAttributes | undefined,
) => void;

const GET_CONFIG_VALUE: GetConfigValueFn = (() => {
  if (isEmpty(API_KEY)) {
    console.error('Config Cat API KEY is not defined, fallback to static values');
    return (key, callback) => {
      callback(DEFAULTS[key]);
    };
  }
  const client = createClient(API_KEY);
  return (key, callback, userAttributes) => {
    client.getValue(key, DEFAULTS[key], callback, toConfigCatUser(userAttributes));
  };
})();

const STORAGE = getStorage(getEnv());

export type FeatureFlagKey = typeof SUPPORTED_FLAGS[number];

export type UserAttributes = Readonly<{
  id: string | undefined;
  email: string | undefined;
  connectedWalletAddress: string | undefined;
}>;

type ContextType = {
  useAttributes: UserAttributes | undefined;
  setUserAttributes: (user: UserAttributes | undefined) => Promise<void>;
};

const Context = React.createContext<ContextType>({
  setUserAttributes: () => {
    throw new Error('Cannot use outside of FeatureFlagsContextProvider');
  },
  useAttributes: undefined,
});

const toConfigCatUser = (user: UserAttributes | undefined): ConfigCatUser | undefined => {
  if (user == null) {
    return undefined;
  }

  const custom = Object.entries(user)
    .map(([key, value]) => {
      if (['id', 'email'].includes(key) || value == null) {
        return undefined;
      }
      return [key, value];
    })
    .filter(notNullOrUndefined);
  return {
    custom: Object.fromEntries(custom),
    email: user.email,
    identifier: user.id ?? SESSION_ID,
  };
};

export const FeatureFlagsContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [user, setUserState] = useState<UserAttributes | undefined>();
  const setUser = useCallback(
    async (value: UserAttributes | undefined) => {
      setUserState(value);
      await STORAGE.setUserAttributes(value ?? null);
    },
    [setUserState],
  );

  useEffect(() => {
    const init = async () => {
      const value = await STORAGE.getUserAttributes();
      setUserState(value ?? undefined);
    };
    // Intentionally ignore error
    init().catch();
  }, []);

  return (
    <Context.Provider value={{ setUserAttributes: setUser, useAttributes: user }}>
      {children}
    </Context.Provider>
  );
};

export const useFeatureFlag = (key: FeatureFlagKey): boolean => {
  const { useAttributes: user } = useContext(Context);
  const [value, setValue] = useState(DEFAULTS[key]);
  useEffect(() => {
    GET_CONFIG_VALUE(key, setValue, user);
  }, [user, key, setValue]);
  return value;
};

export const useUpdateFeatureFlagsUser = (): ((
  user: UserAttributes | undefined,
) => Promise<void>) => {
  return useContext(Context).setUserAttributes;
};
