import { NotifiFrontendClient } from '@notifi-network/notifi-frontend-client';
import { Action as DispatchAction, Dispatch } from '@reduxjs/toolkit';

export enum ClientActionTypes {
  SET_CLIENT = 'SET_CLIENT',
  RESET_CLIENT = 'RESET_CLIENT',
}

// First, define interfaces for each action type
interface SetClientAction {
  type: ClientActionTypes.SET_CLIENT;
  payload: NotifiFrontendClient;
}

interface ResetClientAction {
  type: ClientActionTypes.RESET_CLIENT;
}

// Then, define ClientActions as a union of these action types
export type ClientActions = SetClientAction | ResetClientAction;

// Thunk action creators
export const setClient = (client: NotifiFrontendClient) => (dispatch: Dispatch) => {
  dispatch({
    meta: {
      serializableCheck: false,
    },
    payload: client,
    type: ClientActionTypes.SET_CLIENT,
  });
};

export const resetClient = () => {
  return (dispatch: Dispatch<DispatchAction>) => {
    return dispatch({
      type: ClientActionTypes.RESET_CLIENT,
    });
  };
};
