import { Types } from '@notifi-network/notifi-graphql';

export const UPDATE_TARGET_DATA = 'UPDATE_TARGET_DATA' as const;
export const CLEAR_TARGET_DATA = 'CLEAR_TARGET_DATA' as const;

export interface TargetData {
  emailTarget?: string;
  emailTargetId?: string;
  telegramTarget?: string;
  telegramTargetId?: string;
  emailConfirmation: boolean;
  telegramConfirmation: boolean;
  telegramConfirmationUrl?: string;
  targetGroupId?: string;
  discordAccountId?: string;
  discordUsername?: string;
  discordDiscriminator?: string;
  discordUserStatus?: string;
  discordTargetId?: string;
  discordIsConfirmed?: boolean;
  discordVerificationLink?: string;
}

export const updateTargetData = (targetGroup: Types.TargetGroupFragmentFragment[]) => ({
  payload: targetGroup,
  type: UPDATE_TARGET_DATA,
});

export const clearTargetData = () => ({
  type: CLEAR_TARGET_DATA,
});

export type TargetActions =
  | ReturnType<typeof updateTargetData>
  | ReturnType<typeof clearTargetData>;
