import {
  CLEAR_TARGET_DATA,
  TargetActions,
  TargetData,
  UPDATE_TARGET_DATA,
} from '../actions/targetActions';

const initialState: TargetData = {
  discordAccountId: undefined,
  discordDiscriminator: undefined,
  discordIsConfirmed: undefined,
  discordTargetId: undefined,
  discordUserStatus: undefined,
  discordUsername: undefined,
  discordVerificationLink: undefined,
  emailConfirmation: false,
  emailTarget: undefined,
  emailTargetId: undefined,
  targetGroupId: undefined,
  telegramConfirmation: false,
  telegramConfirmationUrl: undefined,
  telegramTarget: undefined,
  telegramTargetId: undefined,
};

export const targetReducer = (state = initialState, action: TargetActions): TargetData => {
  switch (action.type) {
    case UPDATE_TARGET_DATA: {
      const fusionData = action.payload[0];
      return {
        discordAccountId: fusionData?.discordTargets?.[0]?.discordAccountId ?? undefined,
        discordDiscriminator: fusionData?.discordTargets?.[0]?.discriminator ?? undefined,
        discordIsConfirmed: fusionData?.discordTargets?.[0]?.isConfirmed,
        discordTargetId: fusionData?.discordTargets?.[0]?.id ?? undefined,
        discordUserStatus: fusionData?.discordTargets?.[0]?.userStatus ?? undefined,
        discordUsername: fusionData?.discordTargets?.[0]?.username ?? undefined,
        discordVerificationLink: fusionData?.discordTargets?.[0]?.verificationLink ?? undefined,
        emailConfirmation: fusionData?.emailTargets?.[0]?.isConfirmed,
        emailTarget: fusionData?.emailTargets?.[0]?.emailAddress ?? undefined,
        emailTargetId: fusionData?.emailTargets?.[0]?.id ?? undefined,
        targetGroupId: fusionData?.id ?? undefined,
        telegramConfirmation: fusionData?.telegramTargets?.[0]?.isConfirmed,
        telegramConfirmationUrl: fusionData?.telegramTargets?.[0]?.confirmationUrl ?? undefined,
        telegramTarget: fusionData?.telegramTargets?.[0]?.telegramId ?? undefined,
        telegramTargetId: fusionData?.telegramTargets?.[0]?.id ?? undefined,
      };
    }
    case CLEAR_TARGET_DATA:
      return initialState;
    default:
      return state;
  }
};
