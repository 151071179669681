import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { ClientActionTypes } from './store/actions/clientActions';
import alertReducer from './store/reducers/alertReducer';
import { clientReducer } from './store/reducers/clientReducer';
import dashboardReducer from './store/reducers/dashboardReducer';
import dataReducer from './store/reducers/dataReducer';
import loginReducer from './store/reducers/loginReducer';
import { targetReducer } from './store/reducers/targetReducer';

const combinedReducers = combineReducers({
  alert: alertReducer,
  client: clientReducer,
  dashboard: dashboardReducer,
  data: dataReducer,
  targets: targetReducer,
  user: loginReducer,
});

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [ClientActionTypes.SET_CLIENT],
        ignoredPaths: ['client.client'],
      },
    }),
  reducer: combinedReducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
