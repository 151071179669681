/* eslint-disable @typescript-eslint/no-explicit-any */

import { WalletBlockchain } from 'services/gql/generated';

import Action from '../actions/types';

export const DEFAULT_WALLET_BLOCKCHAIN = 'SOLANA';

export type DappLogInInput = {
  accountId?: string;
  walletPublicKey: string;
  dappAddress: string;
  timestamp: number;
  walletBlockchain?: WalletBlockchain;
};

type UnauthenticatedUserState = {
  isAuthenticated: false;
};

export type AuthenticatedUserState = {
  isAuthenticated: true;
};

type UserState = UnauthenticatedUserState | AuthenticatedUserState;

const initialState: UnauthenticatedUserState = {
  isAuthenticated: false,
};

// FIXME
export default function loginReducer(state: UserState = initialState, action: any) {
  switch (action.type) {
    case Action.LOGIN_USER_SUCCESS:
      return {
        isAuthenticated: true,
      };
    case Action.LOGIN_USER_FROM_DAPP_SUCCESS:
      return {
        dappLogInInput: action.payload.dappLogInInput,
        isAuthenticated: true,
        signature: action.payload.signature,
      };
    case Action.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
